@import '~antd/dist/antd.css';

div.ant-table-wrapper {
    border-radius: 15px;
    overflow: hidden;
}

div.my-table-wrapper::-webkit-scrollbar {
    display: none; /* Chrome Safari */
}

div.my-table-wrapper {
    -ms-overflow-style: none; /* IE 10+ */
    scrollbar-width: none; /* Firefox */
}

div.ant-table-content > table > thead.ant-table-thead > tr > th.ant-table-cell {
    padding: 8px 1px;
}

div.ant-table-content > table > thead.ant-table-thead > tr > th.ant-table-cell:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0;
}

div.ant-table-content > table > tbody.ant-table-tbody > tr.ant-table-row > td.ant-table-cell {
    padding: 8px 1px;
}

div.ant-table-content > table > .ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background-color: rgba(255, 192, 203, 0.62);
}

div.ant-drawer.ant-drawer-open .ant-drawer-content-wrapper {
    max-width: 100%;
}

.InfoButtonWrapper Button {
    width: 90%;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1em;
    border-radius: 20px;
    border: #000c17 solid 2px;
}

.InfoButtonWrapper Button .BtnContent {
    vertical-align: text-bottom;
    font-size: 18px;
    line-height: 1em;
}

.InfoButtonWrapper Button .BtnContent > img {
    position: absolute;
    width: 18px;
    height: 18px;
    left: 20px;
    border-radius: 5px;
}

div.chat-pannel {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 999;
}

div.chat-pannel-in {
    display: none;
}

div.chat-pannel-in.show {
    display: block;
}

div.nickname-btn,
div.send-msg-btn {
    width: 120px;
    height: 30px;
    background-color: rgba(57, 197, 187, 0.9);
    text-align: center;
    line-height: 30px;
    border-radius: 15px;
    cursor: pointer;
    margin-bottom: 10px;
}

div.alive-cnt {
    width: 120px;
    height: 30px;
    background-color: rgba(240, 248, 255, 0.9);
    text-align: center;
    line-height: 30px;
    border-radius: 15px;
    cursor: pointer;
    margin-bottom: 10px;
}

div.chat-view {
    width: 300px;
    max-width: 100%;
    position: fixed;
    bottom: 10px;
    left: 10px;
    max-height: 50vh;
    background-color: rgba(255, 255, 255, 0.2);
    overflow-y: auto;
    z-index: 999;
    border-radius: 15px;
}

div.chat-view.hide {
    display: none;
}

div.chat-view>.chat-wrapper:first-child {
    margin-top: 0;
}

div.chat-wrapper {
    display: flex;
    flex-direction: row;
    background-color: rgba(242, 242, 242, 0.9);
    padding: 5px 15px 5px 5px;
    border-radius: 15px;
    margin: 10px 0 0 0;
    transition: all 0.6s ease;
    position: relative;
    overflow-x: hidden;
}

div.chat-profile {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
    background-color: azure;
    text-align: center;
    line-height: 30px;
}

div.chat-content-wrapper {
    padding-left: 10px;
}

div.chat-nickname {
    padding-left: 10px;
    font-weight: bolder;
}

div.chat-content {
    white-space: normal;
    word-break: break-all;
}
